<template>
    <div class="wrapper">
        <div class="wrapper-inner">
            <div class="wrapper-inner-top">
                <div class="wrapper-inner-top-left">采集情况</div>
                <div class="wrapper-inner-top-right">
                    <div class="one-item">
                        <img
                            class="icon"
                            src="@/assets/images/total-num.png"
                        />
                        <div class="word">
                            应填人数：<span>{{ expectedNum ? expectedNum : "-"}}</span
                            >{{ expectedNum ? "人" : ""}}
                        </div>
                    </div>
                    <div class="one-item">
                        <img
                            class="icon"
                            src="@/assets/images/one-num.png"
                        />
                        <div class="word">
                            实填人数：<span>{{ actualNum }}</span
                            >人
                        </div>
                    </div>
                    <div class="one-item">
                        <img
                            class="icon"
                            src="@/assets/images/one-num.png"
                        />
                        <div class="word word1">
                            无效答卷：<span>{{ invalidNum }}</span
                            >人
                        </div>
                    </div>
                </div>
            </div>
            <div class="wrapper-inner-b">
                <div class="wrapper-inner-bottom">
                    <div class="paper-title">
                        {{ formName }}
                    </div>
                    <div
                        v-if="!nullData"
                        class="paper-list"
                    >
                        <div
                            class="paper-item"
                            v-for="(item, index) in paperList"
                            :key="index"
                        >
                            <div class="paper-item-title-box">
                                <div class="paper-item-title">
                                    {{ item.lable }}
                                </div>
                                <div
                                    v-if="mesCom.includes(item.compType)"
                                    class="paper-item-type"
                                >
                                    [{{
                                        item.compType == "multipleSelect"
                                            ? "多选"
                                            : "单选"
                                    }}]
                                </div>
                                <div v-if="mesCom.includes(item.compType)" @click="goToDetail(item)" class="selectMes">查看详情</div>
                            </div>
                            <div class="paper-table">
                                <el-table
                                    v-if="mesCom.includes(item.compType)"
                                    :data="item.options"
                                    border
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        align="center"
                                        prop="label"
                                        label="选项"
                                    >
                                        <template slot-scope="scope">
                                            <div class="table-options">
                                                {{ scope.row.label }}
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="count"
                                        align="center"
                                        label="数量"
                                        width="180"
                                    >
                                        <template slot-scope="scope">
                                            <span>
                                                {{
                                                    scope.row.count
                                                        ? scope.row.count
                                                        : 0
                                                }}
                                            </span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="precent"
                                        align="center"
                                        label="比例"
                                    >
                                        <template slot-scope="scope">
                                            <el-progress
                                                :percentage="
                                                    parseFloat(
                                                        scope.row.percent
                                                            ? scope.row.percent
                                                            : 0,
                                                    )
                                                "
                                            ></el-progress>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <div v-else>
                                    <div
                                        class="plain-btn"
                                        @click="goToDetail(item)"
                                    >
                                        查看详细信息
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="nullData"
                        v-else
                    >
                        <div>
                            <img
                                class="status-img"
                                src="@/assets/images/nullData.png"
                            />
                        </div>
                        <div>暂无数据</div>
                    </div>
                </div>
                <div class="status-img-box">
                    <img
                        class="status-img"
                        v-if="releaseStatus == 2"
                        src="@/assets/images/progress-status.png"
                    />
                    <img
                        class="status-img"
                        v-if="releaseStatus == 3"
                        src="@/assets/images/end-status.png"
                    />
                    <img
                        v-if="releaseStatus == 1"
                        class="status-img"
                        src="@/assets/images/release-status.png"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {OverallResults} from "@/models/OverallResults.js";
export default {
    name: "OverallResults",
    props: ["id", "formName", "releaseStatus"],
    data() {
        return {
            mesCom: ["multipleSelect", "radio", "rate"],
            paperList: [],
            expectedNum: 0,
            actualNum: 0,
            invalidNum: 0,
            nullData: true,
        };
    },
    created() {
        this.getTotal();
        this.getList();
    },
    methods: {
        goToDetail(item) {
            this.$emit("changeComp", {
                comp: "OverallResultsDetails",
                formObj: item,
            });
        },
        getList() {
            let modeApi = new OverallResults();

            modeApi
                .getListData({
                    id: this.id,
                })
                .then((res) => {
                    if (res.data.code == 200) {
                        if (Array.isArray(res.data.data)) {
                            this.nullData = false;
                            this.paperList = res.data.data;
                        } else {
                            this.nullData = true;
                        }
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        getTotal() {
            let modeApi = new OverallResults();
            modeApi.getCount(this.id).then((res) => {
                if (res.data.code == 200) {
                    let data = res.data.data;
                    this.expectedNum = data.expectedNum;
                    this.actualNum = data.actualNum;
                    this.invalidNum = data.invalidNum;
                } else {
                    this.$message.error(res.data.msg);
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
    min-width: 800px;
    overflow-x: auto;
    .wrapper-inner {
        width: 800px;
        padding-top: 10px;

        .wrapper-inner-top {
            height: 54px;
            width: 100%;
            background: #fafcff;
            border-radius: 4px;
            margin-bottom: 12px;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            padding-left: 28px;

            .wrapper-inner-top-left {
                font-family: Microsoft YaHei;
                font-weight: bold;
                font-size: 14px;
                color: #505559;
                border-right: 1px solid #d7d9db;
                padding-right: 24px;
            }

            .wrapper-inner-top-right {
                display: flex;
                align-items: center;

                .one-item {
                    display: flex;
                    margin-left: 30px;

                    .icon {
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        margin-right: 10px;
                    }

                    .word {
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #2b2f33;

                        span {
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            font-size: 16px;
                            color: #2b2f33;
                            // margin-left: 10px;
                        }
                    }

                    .word1 {
                        span {
                            color: #f23131;
                        }
                    }
                }
            }
        }

        .wrapper-inner-b {
            position: relative;

            .wrapper-inner-bottom {
                height: calc(100vh - 260px);
                background: #f9f9f9;
                border-radius: 10px 10px 0px 0px;
                overflow-y: auto;
                box-sizing: border-box;
                padding: 32px 30px;
                margin-bottom: 10px;
                .paper-title {
                    text-align: center;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    font-size: 18px;
                    color: #2b2f33;
                }

                .paper-item-title-box {
                    display: flex;
                    margin: 20px 0;

                    .paper-item-title {
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        font-size: 16px;
                        color: #333333;
                    }

                    .paper-item-type {
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        font-size: 16px;
                        color: #999999;
                        flex: 1;
                    }
                }
                .selectMes{
                    color: #3c7fff;
                    cursor: pointer;
                }
            }

            .status-img-box {
                width: 110px;
                height: 110px;
                position: absolute;
                top: -54px;
                right: -54px;
                z-index: 10;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
.plain-btn {
    cursor: pointer;
    background: #edf0f7;
    border-radius: 4px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #3c7fff;
    text-align: center;
    width: 104px;
    height: 32px;
    line-height: 32px;
}
</style>
<style lang="scss">
.paper-table {
    .el-table th.el-table__cell {
        background: #edf0f7;
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 14px;
        color: #333333;
    }

    .table-options {
        text-align: left;
        box-sizing: border-box;
        padding-left: 10px;
    }
}
.nullData {
    height: 90%;
    overflow: hidden;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 16px;
    color: #999999;
    line-height: 36px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>
