<template>
    <div class="details-wrapper">
        <global-page-back
            :detailPageTitle="formObj.lable"
            @handlerGlobalType="handlerGlobalType"
        ></global-page-back>
        <div class="details-content">
            <div class="filter-wrapper">
                <div>
                    <el-input
                        class="formItem"
                        v-model="findObj.filledName"
                        placeholder="请输入姓名"
                        clearable
                        v-if="isShowFilter"
                    ></el-input>
                    <el-cascader
                        v-model="findObj.organIdList"
                        v-if="isShowFilter"
                        class="formItem"
                        :options="zzOption"
                        placeholder="请选择组织架构"
                        :props="props"
                        collapse-tags
                        clearable
                    ></el-cascader>
                    <el-select
                        v-if="itemType == '多选'"
                        v-model="findObj.answerInfoList"
                        multiple
                        collapse-tags
                        class="formItem"
                        placeholder="请选择填写内容"
                        clearable
                    >
                        <el-option
                            v-for="item in xiaOption"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                    <el-select
                        v-if="itemType == '单选' || itemType == '满意度'"
                        v-model="findObj.answerInfo"
                        class="formItem"
                        placeholder="请选择填写内容"
                        clearable
                    >
                        <el-option
                            v-for="item in xiaOption"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                    <el-input
                        v-if="itemType == '其他' && itemType != '图片'"
                        class="formItem"
                        v-model="findObj.answerInfo"
                        placeholder="请输入填写内容"
                        clearable
                    ></el-input>
                </div>
                <div class="fiBtn">
                    <el-button @click="resetBtn">重置</el-button>
                </div>
                <div class="fiBtn">
                    <el-button
                        type="primary"
                        @click="searchBtn"
                        >查询</el-button
                    >
                </div>
                <div class="btnLine"></div>
                <div class="fiBtn">
                    <el-button
                        :loading="exportLoading"
                        type="primary"
                        @click="exportList"
                        >导出全部数据</el-button
                    >
                </div>
            </div>
            <div class="table-wrapper">
                <table-data
                    v-loading="loadingTable"
                    :to-top="false"
                    id="table"
                    ref="table"
                    :config="tableConfig"
                    :tableData="tableData"
                >
                    <template v-slot:answerInfo="slotData">
                        <div v-if="slotData.data.answerInfo">
                            <span v-if="!isImage">{{
                                slotData.data.answerInfo
                            }}</span>
                            <el-image
                                v-else
                                style="width: 50px; height: 50px"
                                :src="slotData.data.answerInfo"
                                :preview-src-list="[slotData.data.answerInfo]"
                            >
                            </el-image>
                        </div>
                        <div v-else>-</div>
                    </template>
                    <template v-slot:formTitle="slotData">
                        <div
                            style="color: #3c7fff; cursor: pointer"
                            @click="openDetails(slotData.data)"
                        >
                            {{ slotData.data.name }}
                        </div>
                    </template>
                </table-data>
            </div>
            <div style="flex-shrink: 0; padding-bottom: 10px">
                <Pagination
                    :page.sync="findObj.pageNum"
                    :limit.sync="findObj.pageRow"
                    :total="findObj.total"
                    @pagination="changeTableDataGet"
                />
            </div>
        </div>
    </div>
</template>

  <script>
import {Loading, Error, Pagination} from "common-local";
import GlobalPageBack from "@/components/scrollWrapper/Sub/GlobalPageBack/index.vue";
import TableData from "@/components/scrollWrapper/Sub/TableData";
import {mapState} from "vuex";
import ExpandFilterForm from "../Sub/ExpandFilter";
import {listToTree, downloadFile} from "@/libs/utils.js";
import {forEach} from "lodash";
export default {
    props: ["formObj", "id", "userPopulation"],
    components: {
        GlobalPageBack,
        TableData,
        Loading,
        Error,
        Pagination,
        ExpandFilterForm,
    },
    data() {
        return {
            exportLoading: false,
            detailPageTitle: "详情",
            loadingTable: false,
            tableData: [{}],
            findObj: {
                schoolId: "",
                formId: "",
                titleKey: "",
                type: "2",
                pageNum: 1,
                pageRow: 20,
                total: 0,
                answerInfoList: [],
                filledName: "",
                organIdList: [],
                userPopulation: "",
                answerInfo: "",
            },
            itemType: "其他",
            isShowFilter: true,
            selectOption: [],
            tableConfig: {
                thead: [
                    {
                        label: "填表人姓名",
                        // labelWidth: '180px',
                        minWidth: "200px",
                        prop: "filledName",
                        align: "center",
                        type: "function",
                        callBack: (row) => {
                            return row.filledName ? row.filledName : "-";
                        },
                    },
                    {
                        label: "所在组织架构",
                        // labelWidth: '180px',
                        minWidth: "200px",
                        prop: "organName",
                        align: "center",
                        type: "function",
                        callBack: (row) => {
                            return row.organName ? row.organName : "-";
                        },
                    },
                    {
                        label: "填写内容",
                        minWidth: "200px",
                        type: "slot",
                        slotName: "answerInfo",
                        prop: "answerInfo",
                        tooltip: true,
                        align: "center",
                    },
                ],
                height: "",
                check: false,
            },

            formData: {
                data: [
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "请输入教师名称",
                        key: "teacherName",
                    },
                    {
                        type: "cascader",
                        label: "",
                        value: "",
                        placeholder: "请选择任教班级",
                        key: "classId",
                        cascaderProps: {
                            label: "name",
                            multiple: false,
                            value: "id",
                            checkStrictly: true,
                            emitPath: false,
                        },
                        list: [],
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "请选择任教科目",
                        key: "subjectId",
                        list: [],
                    },
                    {
                        type: "slot",
                        slotName: "lowScore",
                        fixed: false,
                        label: "",
                        value: "",
                        placeholder: "请输入最低得分",
                        key: "lowScore",
                    },
                    {
                        type: "slot",
                        slotName: "highScore",
                        fixed: false,
                        label: "",
                        value: "",
                        placeholder: "请输入最高得分",
                        key: "highScore",
                    },
                ],
                btnList: [
                    {
                        type: "enquiry",
                        text: "重置",
                        fn: "reset",
                    },
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                    },
                ],
                btnFormType: false,
            },

            isImage: false,

            props: {
                multiple: true,
                emitPath: false,
                checkStrictly: true,
                value: "id",
                label: "name",
            },
            zzOption: [], // 组织架构
            xiaOption: [], // 下拉内容
        };
    },
    created() {
        console.debug(JSON.parse(JSON.stringify(this.formObj)));
        this.findObj.schoolId = this.$store.state.schoolId;
        this.findObj.formId = this.id;
        this.findObj.titleKey = this.formObj.uid;
        this.findObj.userPopulation = this.userPopulation;

        if (this.formObj.compType == "upload") {
            this.isImage = true;
        }

        // 教职工
        if (this.userPopulation == 1) {
            this.getBMData();
        }

        // 学生家长
        if (this.userPopulation == 2) {
            this.getClassList();
        }

        // 不限
        if (this.userPopulation == 3) {
            this.isShowFilter = false;
        }

        // 下拉多选
        if (this.formObj.compType == "multipleSelect") {
            this.itemType = "多选";
            this.findObj.type = 1;
            this.xiaOption = this.formObj.options; // 下拉内容
        }
        // 单选
        if (this.formObj.compType == "radio") {
            this.itemType = "单选";
            this.findObj.type = 1;
            this.xiaOption = this.formObj.options; // 下拉内容
        }
        // 满意度
        if (this.formObj.compType == "rate") {
            this.itemType = "满意度";
            this.findObj.type = 1;
            this.xiaOption = this.formObj.options; // 下拉内容
        }
        // 图片
        if (this.formObj.compType == "upload") {
            this.itemType = "图片";
        }
    },
    mounted() {
        this.tableConfig.height =
            document.body.clientHeight -
            document.getElementById("table").offsetTop -
            146;

        this.getListData();
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
            groupId: (state) => state.approvalManagementGroupId,
        }),
    },

    methods: {
        /**
         * @Description: 拿取部门数据
         * @Author: 周浩
         * @Date: 2024-10-16 14:07:14
         */
        getBMData() {
            this._fet("/school/schoolOrgan/listByConditionNoAuth", {
                schoolId: this.schoolId,
            }).then((res) => {
                if (res.data.code == 200) {
                    let allList = res.data.data.list.filter(
                        (it) => it.organType == 1,
                    );

                    let options = listToTree(allList, {
                        parentKey: "parentOrg",
                    });
                    this.clearDataNull(options, "children");
                    this.zzOption = options;
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        /**
         * @Description: 获取任教班级
         * @Author: 王梦瑶
         * @Date: 2024-08-16 10:42:18
         */
        getClassList() {
            let obj = {
                schoolId: this.$store.state.schoolId,
            };
            this._fet("/school/schoolOrgan/listByCondition", obj).then(
                (res) => {
                    if (res.data.code === "200") {
                        let arr = res.data.data.list.filter((item) => {
                            return item.organType != 1;
                        });
                        const treeData = listToTree(arr, {
                            parentKey: "parentOrg",
                        });

                        this.clearDataNull(treeData, "children");
                        this.zzOption = treeData;
                    } else {
                        this.$message.error("数据请求失败，请重试");
                    }
                },
            );
        },
        clearDataNull(data, key) {
            data.forEach((item) => {
                if (
                    item[key] == null ||
                    item[key] == undefined ||
                    item[key].length == 0
                ) {
                    delete item[key];
                } else {
                    this.clearDataNull(item[key], key);
                }
            });
        },
        /**
         * @Description: 筛选栏按钮操作
         * @Author: 王梦瑶
         * @Date: 2023-2-17 11:22:02
         */
        clickBtn(type) {
            switch (type.item.fn) {
                case "primary":
                    // 查询

                    break;
                case "reset":
                    // 查询重置

                    break;
            }
        },
        handlerGlobalType() {
            this.$emit("changeComp", {
                comp: "OverallResult",
            });
        },
        changeTableDataGet() {
            this.getListData();
        },
        searchBtn() {
            this.getListData();
        },
        getListData() {
            this._fet("/school/schoolCollectDetails/list", this.findObj).then(
                (res) => {
                    if (res.data.code == 200) {
                        let data = res.data.data.list;
                        // if (
                        //     this.itemType == "单选" ||
                        //     this.itemType == "多选"
                        // ) {
                        //     data.forEach((item) => {
                        //         let arr = item.answerInfo.split(",");
                        //         arr.forEach((item, index) => {
                        //             arr[index] = this.xiaOption.find(
                        //                 (item2) => {
                        //                     return item2.value == item;
                        //                 },
                        //             ).label;
                        //         });
                        //         item.answerInfo = arr.join(",");
                        //     });
                        // }

                        // if (this.itemType == "满意度") {
                        //     data.forEach((item) => {
                        //         let xing = "★";
                        //         let str = "";
                        //         for (let i = 0; i < item.answerInfo; i++) {
                        //             str += xing;
                        //         }
                        //         item.answerInfo = str;
                        //     });
                        // }

                        this.tableData = data;
                        this.findObj.total = res.data.data.total;
                    } else {
                        this.$message.error(res.data.message);
                    }
                },
            );
        },
        /**
         * @Description: 导出全部
         * @Author: 王梦瑶
         * @Date: 2024-08-22 11:43:59
         */
        exportList() {
            this.exportLoading = true;
            downloadFile(
                {
                    url: "/school/schoolCollectDetails/export",
                    method: "post",
                    form: {
                        schoolId: this.$store.state.schoolId,
                        formId: this.id,
                        type: this.findObj.type,
                        titleKey: this.formObj.uid,
                        titleInfo: this.formObj.lable,
                    },
                },
                () => {
                    this.exportLoading = false;
                },
                () => {
                    this.exportLoading = false;
                },
            );
        },
        resetBtn() {
            this.findObj = this.$options.data().findObj;
            this.findObj.schoolId = this.$store.state.schoolId;
            this.findObj.formId = this.id;
            this.findObj.titleKey = this.formObj.uid;
            this.findObj.userPopulation = this.userPopulation;
            // 下拉多选
            if (this.formObj.compType == "multipleSelect") {
                this.findObj.type = 1;
            }
            // 单选
            if (this.formObj.compType == "radio") {
                this.findObj.type = 1;
            }
            // 满意度
            if (this.formObj.compType == "rate") {
                this.findObj.type = 1;
            }
            this.getListData();
        },
    },
};
</script>

<style lang="scss" scoped>
.details-wrapper {
    height: calc(100vh - 120px);

    display: flex;
    flex-direction: column;
    overflow: hidden;
    .details-content {
        flex: 1;
        overflow: hidden;
        // height: calc(100vh - 180px);
        background-color: #fff;
        margin-top: 10px;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .filter-wrapper {
            flex-shrink: 0;
            display: flex;
            text-align: center;
            align-items: center;
            background-color: #fff;
            overflow: hidden;
            padding: 10px 0px;

            .formItem {
                margin: 0 5px;
                width: 140px;
            }

            .fiBtn {
                margin: 0 5px;
            }
            .btnLine {
                width: 1px;
                height: 25px;
                border-right: 1px dashed #d4d6d9;
                margin: 0 10px;
            }
        }

        .table-wrapper {
            padding-right: 5px;
            flex: 1;
            overflow: hidden;
        }
    }
}
::v-deep {
    .el-input__inner {
        background: #f5f5f5;
        border: transparent 1px solid;
    }
    .el-range-editor .el-range-input {
        background: #f5f5f5;
    }
    .el-cascader__search-input {
        background: #f5f5f5;
    }
    .el-select .el-input .el-select__caret {
        color: #999999;
    }
    .el-input-group__append {
        color: #999999;
        background: #f5f5f5;
        border: none;
    }
    .el-select-dropdown__list {
        border: 1px solid #d4d6d9;
    }
    .el-input__inner:hover {
        border: #d4d6d9 1px solid;
    }
    .el-input__inner:focus {
        border: #3c7fff 1px solid;
        background-color: #fff;
    }
    .el-cascader__tags {
        & > span:nth-of-type(1) {
            flex: 1 !important;
            overflow: hidden;
        }
        & > span:nth-of-type(2) {
            flex-shrink: 0 !important;
            overflow: hidden;
        }
    }
}
</style>
